import React, { useState } from "react";
import { graphql, useStaticQuery } from "gatsby"
import SelectLanguage from "./SelectLanguage";
import "bootstrap/dist/css/bootstrap.min.css";
import './components.css';
import '../assets/scss/custom.scss';
import {
  Collapse,
  Container,
  Row,
  Col,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  Nav,
  NavLink,
} from "reactstrap";
import logo from '../static/images/logo-npi.svg'



const Header = props => {

  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const query = useStaticQuery(graphql`
  query NavigationLinks {
    site {
      siteMetadata {
        menuLinks {
          top {
            link
            name
            language
          }
        }
      }
    }
  }
`)

const topItems = query.site.siteMetadata.menuLinks.top.map(
  ({ name, link, language }) => {
    if (language === props.languag) {
    
      return (
        <NavItem>
          <NavLink
            href={link}
          >
            {name}
          </NavLink>
        </NavItem>
      )
    }
    return null
  }
)

  return (
    <Container fluid className="header-container">
      <Row>
        <Col className="col">
          <Navbar light expand="md">
            <Container fluid>
              <NavbarBrand href="/"><img src={logo} alt="logo" className="logo" height="70"/></NavbarBrand>
              <NavbarToggler onClick={toggle} />
              <Collapse isOpen={isOpen} navbar>
                <Nav navbar className="navigation">
                {topItems}
                </Nav>
                <SelectLanguage className="select-language" langs={props.langs} selectedLang={props.languag}>
                </SelectLanguage>
              </Collapse>
            </Container>
          </Navbar>
        </Col>
      </Row>
    </Container>
  );
};

export default Header;
