import React from "react";
import Link from "gatsby-link";
import { graphql, useStaticQuery } from "gatsby"
import {
  Button,
  Container,
  Row,
  Col
} from "reactstrap";
import logoWhite from "../static/images/logo-white.svg"
import downloadFile from '../static/images/np-plan2.pdf'

const Header = props => {

const query = useStaticQuery(graphql`
query footerLinks {
  site {
    siteMetadata {
      menuLinks {
        footer {
          link
          name
          language
        }
      }
    }
  }
}
`)

const footerItems = query.site.siteMetadata.menuLinks.footer.map(
  ({ name, link, language }) => {
    if (language === props.location) {
    
      return (
           <li>
                <Link to={link}>{name}</Link>
              </li>
       
      )
    }
    return null
  }
)

return (
 
  <div>
    <footer className="footer shadow">
      <Container className="padding40">
        <Row>
          <Col md={3} className="footer-item-title">
            <div>
              <h5>{props.location === "en" ? "Our office" : "Kancelária"}</h5>
              <p>
              Štúrova 27<br/>
              040 01 Košice<br/>
              Slovak Republic</p>
            </div>
          </Col>
          <Col md={3} className="footer-item">
            <h5>{props.location === "en" ? "Buildings" : "Budovy"}</h5>
            <ul>
              {footerItems}
            </ul>
          </Col>
          <Col md={3} className="footer-item">
            <h5>{props.location === "en" ? "Useful shortcuts" : "Užitočné odkazy"}</h5>
            <ul>
              {/*<li>
              <a href={downloadFile} download>{props.location === "en" ? "Plan" : "Plán"}</a>
              </li>*/}
              <li>
                <a href="https://goo.gl/maps/KRb7muyKHcsVYaU49">{props.location === "en" ? "Location" : "Lokalita"}</a>
              </li>
            </ul>
          </Col>
          <Col md={3}>
          <Link to="/kontakt/"> <Button className="button-basic primary-white">
          {props.location === "en" ? "Contact us" : "Kontaktujte nás "}<i className="fa fa-chevron-circle-right"></i>
              </Button> </Link>
          </Col>
        </Row>
      </Container>
      <Container fluid className="footer-copyrights">
        <Container fluid className="container-places">
          <Row>
            <Col className="copyright-text">
              <img src={ logoWhite } alt="company logo" className="text-center" height="80"/>
              <p className="text-center"> © 2012-{new Date().getFullYear()} Nová Polhora Invest, s.r.o. All Rights Reserved | Crafted by <a className="text-light" href ="http://adaptiware.company/sk/">Adaptiware.company</a> </p>
            </Col>
          </Row>
        </Container>
      </Container>
    </footer>
  </div>
  );
};

export default Header;
