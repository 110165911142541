import React from "react";
import PropTypes from "prop-types";
import Link from "gatsby-link";
import { FormattedMessage } from "react-intl";
import ReactCountryFlag from "react-country-flag"
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";

const SelectLanguage = props => {
  const links = props.langs.map(lang => (
    <Link to={lang.langKey === "en" ?  "/" + lang.langKey : "/"}key={lang.langKey}>
      <ReactCountryFlag className="mx-auto my-auto" countryCode={lang.langKey === "en" ? "gb" : lang.langKey} svg /> &nbsp;
    </Link>

  ));

  return (
    <div>
      {/* <UncontrolledDropdown inNavbar className="language">
        <DropdownToggle caret>
            <ReactCountryFlag className="mx-auto my-auto" countryCode={props.selectedLang === "en" ? "gb" : props.selectedLang} svg />
        </DropdownToggle>
        <DropdownMenu right>{links}</DropdownMenu>
      </UncontrolledDropdown> */}
       {links}
    </div>
  );
};

SelectLanguage.propTypes = {
  langs: PropTypes.array
};

export default SelectLanguage;
